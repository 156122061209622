export const REPORT_VOLUME_SALES = 1
export const REPORT_NOT_DEALER = 2
export const REPORT_ADJUSTMENTS_SALES = 3

export const REPORT_TABS = [
  {
    key: 1,
    title: "Отчет по объемам реализации",
  },
  {
    key: 2,
    title: "Нет на складе дилера",
  },
  {
    key: 3,
    title: "Отчет по возвратам",
  },
]

export const REPORT_FILTERS = {
  [REPORT_VOLUME_SALES]: {
    client_id: "",
    dealer_id: "",
    order_status: "",
    period: "",
    price_group: "",
  },
  [REPORT_NOT_DEALER]: {
    client_id: "",
    dealer_id: "",
    order_status: "",
    period: "",
    price_group: "",
  },
  [REPORT_ADJUSTMENTS_SALES]: {
    client_id: "",
    dealer_id: "",
    refund_status: "",
    period: "",
    periodRefund: "",
    price_group: "",
  },
}

export const REPORT_COLUMNS = {
  [REPORT_VOLUME_SALES]: [
    {
      title: "Диллер",
      dataIndex: "dealer_name",
      key: "dealer_name",
    },
    {
      title: "Код диллера",
      dataIndex: "dealer_code",
      key: "dealer_code",
    },
    {
      title: "Номер заказа",
      dataIndex: "order_number",
      key: "order_number",
    },
    {
      title: "Артикул",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Номенклатура",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Товарная группа",
      dataIndex: "price_group",
      key: "price_group",
    },
    {
      title: "Статус заказа",
      dataIndex: "order_status",
      scopedSlots: { customRender: "order_status" },
      key: "order_status",
    },
    {
      title: "Дата",
      dataIndex: "created_at",
      key: "created_at",
    },
    {
      title: "Колличество",
      dataIndex: "item_count",
      key: "item_count",
    },
    {
      title: "Сумма",
      dataIndex: "price_sum",
      key: "price_sum",
    },
  ],
  [REPORT_NOT_DEALER]: [
    {
      title: "Диллер",
      dataIndex: "dealer_name",
      key: "dealer_name",
    },
    {
      title: "Код диллера",
      dataIndex: "dealer_code",
      key: "dealer_code",
    },
    {
      title: "Номер заказа",
      dataIndex: "order_number",
      key: "order_number",
    },
    {
      title: "Артикул",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Номенклатура",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Товарная группа",
      dataIndex: "price_group",
      key: "price_group",
    },
    {
      title: "Статус заказа",
      dataIndex: "order_status",
      scopedSlots: { customRender: "order_status" },
      key: "order_status",
    },
    {
      title: "Дата",
      dataIndex: "created_at",
      key: "created_at",
    },
    {
      title: "Колличество",
      dataIndex: "item_count",
      key: "item_count",
    },
    {
      title: "Сумма",
      dataIndex: "price_sum",
      key: "price_sum",
    },
  ],
  [REPORT_ADJUSTMENTS_SALES]: [
    {
      title: "Диллер",
      dataIndex: "dealer_name",
      key: "dealer_name",
    },
    {
      title: "Код диллера",
      dataIndex: "dealer_code",
      key: "dealer_code",
    },
    {
      title: "Номер заказа",
      dataIndex: "order_number",
      key: "order_number",
    },
    {
      title: "Артикул",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Номенклатура",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Товарная группа",
      dataIndex: "price_group",
      key: "price_group",
    },
    {
      title: "Статус возврата",
      dataIndex: "refund_status",
      scopedSlots: { customRender: "refund_status" },
      key: "refund_status",
    },
    {
      title: "Дата реализации",
      dataIndex: "created_at",
      key: "created_at",
    },
    {
      title: "Дата возврата",
      dataIndex: "refund_date",
      key: "refund_date",
    },
    {
      title: "Колличество",
      dataIndex: "item_count",
      key: "item_count",
    },
    {
      title: "Сумма",
      dataIndex: "price_sum",
      key: "price_sum",
    },
  ],
}

export const STATUSES = {
  STATUS_NEW: {
    title: "Новый",
    value: 1,
  },
  STATUS_IN_PROGRESS: {
    title: "В работе",
    value: 2,
  },
  STATUS_DELIVERED: {
    title: "Доставлен",
    value: 4,
  },
  STATUS_CANCELLED_BY_CLIENT: {
    title: "Отменен клиентом",
    value: 11,
  },
  STATUS_CANCELLED: {
    title: "Отменен",
    value: 14,
  },
  STATUS_INVOICE_ISSUED: {
    title: "Выставлен счёт",
    value: 21,
  },
  STATUS_PAYMENT_RECEIVED: {
    title: "Оплата получена",
    value: 22,
  },
  STATUS_READY_FOR_SHIPMENT: {
    title: "Готов к отгрузке",
    value: 23,
  },
  STATUS_SHIPPED: {
    title: "Отгружен",
    value: 24,
  },
  STATUS_RECEIVED: {
    title: "Получен",
    value: 25,
  },
}

export const STATUSES_TITLES = {
  1: "Новый",
  2: "В работе",
  4: "Доставлен",
  11: "Отменен клиентом",
  14: "Отменен",
  21: "Выставлен счёт",
  22: "Оплата получена",
  23: "Готов к отгрузке",
  24: "Отгружен",
  25: "Получен",
}

export const STATUSES_REFUND = {
  STATUS_NEW: {
    title: "Новый",
    value: "new",
  },
  STATUS_REVIEW: {
    title: "На рассмотрении",
    value: "review",
  },
  STATUS_ACCEPTED: {
    title: "Согласован",
    value: "accepted",
  },
  STATUS_ACCEPTED_PARTIALLY: {
    title: "Частично согласован",
    value: "accepted_partially",
  },
  STATUS_PENDING_INFO: {
    title: "На уточнение",
    value: "pendinginfo",
  },
  STATUS_REVIEW2: {
    title: "На рассмотрении после уточнения",
    value: "review2",
  },
  STATUS_DECLINED: {
    title: "Отклонена",
    value: "declined",
  },
  STATUS_PRODUCT_WAITING: {
    title: "Ожидание возврата товаров",
    value: "productwaiting",
  },
  STATUS_SENDED_BY_CLIENT: {
    title: "Товары отправлены клиентом",
    value: "sendedbyclient",
  },
  STATUS_COMPLETED: {
    title: "Возврат подтвержден",
    value: "completed",
  },
}

export const STATUSES_REFUND_TITLES = {
  new: "Новый",
  review: "На рассмотрении",
  accepted: "Согласован",
  accepted_partially: "Частично согласован",
  pendinginfo: "На уточнение",
  review2: "На рассмотрении после уточнения",
  declined: "Отклонена",
  productwaiting: "Ожидание возврата товаров",
  sendedbyclient: "Товары отправлены клиентом",
  completed: "Возврат подтвержден",
}
