/*import { DummyApiService } from "@/services";
const service = new DummyApiService();*/
import ProductService from "@/services/api/v1/ProductService"
const service = new ProductService()

const DEFAULT_FILTERS = () => ({
  code: "",
  price_group: "",
  spec: "",
})

export const state = {
  columns: [
    /*  {
      title: "Бренд",
      dataIndex: "brand",
      key: "brand",
    },*/
    {
      title: "Кол-во",
      dataIndex: "bcount",
      align: "center",
      width: 125,
      scopedSlots: { customRender: "bcount" },
    },
    {
      title: "Заказ",
      key: "order",
      width: 135,
      className: "column-action",
      scopedSlots: { customRender: "order" },
    },
    {
      dataIndex: "code",
      key: "code",
      slots: { title: "customCode" },
      scopedSlots: { customRender: "code" },
    },
    {
      title: "Наименование",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Наличие",
      dataIndex: "count",
      key: "count",
    },
    // {
    //   title: "Срок доставки",
    //   dataIndex: "delivery_period",
    //   key: "delivery_period",
    //   scopedSlots: { customRender: "coming" },
    // },
    {
      title: "Применимость",
      key: "spec",
      dataIndex: "spec",
    },

    {
      title: "Склад",
      key: "storage",
      scopedSlots: { customRender: "storage" },
    },
    {
      title: "Цена",
      key: "price",
      scopedSlots: { customRender: "price" },
      sorter: (a, b) => a.price - b.price,
    },
  ],
  details: [],
  districts: [],
  fileData: [],
  paginationOptions: {
    pageSize: 15,
    totalCount: 0,
    currentPage: 1,
  },
  loading: false,
  filters: DEFAULT_FILTERS(),
  previousFilters: DEFAULT_FILTERS(),
}

export const mutations = {
  SET_DETAILS(state, payload) {
    state.details = payload
  },
  SET_FILTER_ITEM(state, payload) {
    state.filters[payload.type] = payload.value
  },
  SET_CURRENT_PAGE(state, payload) {
    state.paginationOptions.currentPage = payload
  },
  SET_DETAILS_TOTAL_COUNT(state, payload) {
    state.paginationOptions.totalCount = payload
  },
  SET_PRODUCT_COUNT(state, { count, index }) {
    state.details[index].count = count
  },
  SET_FILE_DATA(state, payload) {
    state.fileData = payload
  },
  SET_LOADING(state, payload) {
    state.loading = payload
  },
  CLEAR_FILTERS: state => {
    state.paginationOptions.currentPage = 1

    for (let key in state.filters) {
      state.filters[key] = ""
    }
  },
  CHANGE_FORM() {},
}

export const actions = {
  async fetchDetails({ commit, getters }, savePage = false) {
    commit("SET_LOADING", true)

    const paginationOptions = getters.paginationOptions

    let currentPage = paginationOptions.currentPage
    if (!savePage) {
      commit("SET_CURRENT_PAGE", 1)
      currentPage = 1
    }

    let filters = {}

    for (const key in getters.filters) {
      if (getters.filters[key] !== null) {
        filters[key] = getters.filters[key]
      }
    }

    const response = await service.search(
      paginationOptions.pageSize,
      currentPage,
      filters
    )

    commit("SET_LOADING", false)
    commit("SET_DETAILS_TOTAL_COUNT", response.data.meta.total)

    // const addCountProp = items => {
    //   return items.map(item => ({
    //     ...item,
    //     availableCount: item.count,
    //   }))
    // }
    //
    // const formatItems = items => {
    //   return addCountProp(items)
    // }

    commit("SET_DETAILS", response.data.data)
  },
  setDetails({ commit }, payload) {
    commit("SET_DETAILS", payload)
  },
}

export const getters = {
  columns(state, getters, rs, rg) {
    let cols = [...state.columns]

    if (!rg["auth/isBuyer"]) {
      cols = cols.slice(2, cols.length)
    }
    return cols
  },
  details(state) {
    return state.details.map(detail => ({
      ...detail,
      // availableCount: Math.floor(Math.random() * (25 + 1)),
      // deliveryPeriod: Math.floor(Math.random() * (10 - 3 + 1) + 3),
    }))
  },
  fileData(state) {
    return state.fileData
  },
  paginationOptions(state) {
    return state.paginationOptions
  },
  filters(state) {
    return state.filters
  },
  loading(state) {
    return state.loading
  },
}
