<template>
  <div>
    <div :style="{ left: positionLeftBurger }" class="burger" @click="hideMenu">
      <a-icon :type="iconType" :style="{ fontSize: '1.5em', color: 'white' }" />
    </div>
    <a-layout-sider
      class="slider"
      v-model="collapsed"
      :collapsible="false"
      width="250"
      :style="{
        overflow: 'auto',
        height: '100vh',
        position: 'fixed',
        left: positionLeft,
        transition: '.3s',
      }"
    >
      <div class="slider-logo-wrap">
        <div class="slider-logo-image">
          <img src="../assets/images/glogo-horizontal.svg" />
        </div>
      </div>

      <a-divider class="user__divider user__divider-logo" />

      <a-menu
        :open-keys.sync="openKeys"
        class="search-menu__container"
        mode="inline"
        :inlineCollapsed="collapsed"
        theme="dark"
        :selectedKeys="selectedKeys"
      >
        <template v-for="{ name, icon, path, children, id } in currentMenu">
          <template v-if="children">
            <a-sub-menu :key="id">
              <span class="item" slot="title">
                <a-icon :type="icon" />
                <span>{{ name }}</span>

                <!-- Счетчик заказов для клиента -->
                <a-badge
                  v-if="id === 'Orders' && shownCounts"
                  :count="ordersCounts"
                  :number-style="{ marginLeft: '0px' }"
                />

                <!-- Счетчик возвратов-->
                <a-badge
                  v-if="id === 'Refunds' && shownCounts"
                  :count="refundsCounts"
                  :number-style="{ marginLeft: '0px' }"
                />
              </span>
              <a-menu-item
                v-for="subMenuItem in children.items"
                :key="subMenuItem.id"
              >
                <a :href="subMenuItem.path">
                  {{ subMenuItem.name }}

                  <!-- Счетчик заказов для клиента в подменю -->
                  <a-badge
                    v-if="subMenuItem.id === '45' && shownCounts"
                    :count="ordersCounts"
                    :number-style="{ marginLeft: '0px' }"
                  />

                  <!-- Счетчик возвратов для клиента в подменю -->
                  <a-badge
                    v-if="subMenuItem.id === 'RefundItems' && shownCounts"
                    :count="refundsCounts"
                    :number-style="{ marginLeft: '0px' }"
                  />
                </a>
              </a-menu-item>
            </a-sub-menu>
          </template>
          <template v-else>
            <a-menu-item :key="id" @click="onMenuItemClick(path)">
              <a-icon :type="icon" />
              <span v-if="id !== 'Basket'">
                <a class="menu-link" :href="path">{{ name }}</a>

                <!-- Счетчик заказов-->
                <a-badge
                  v-if="id === 'Orders' && shownCounts"
                  :count="ordersCounts"
                  :number-style="{ marginLeft: '0px' }"
                />
              </span>
              <span v-else>
                <a class="menu-link" :href="path">{{ name }}</a>
                <a-badge
                  :count="basketCount"
                  :number-style="{ marginLeft: '0px' }"
                />
              </span>
            </a-menu-item>
          </template>
        </template>
      </a-menu>
      <div class="user">
        <a-divider class="user__divider" />
        <div class="user__content">
          <div class="diller-info" v-if="isBuyer">
            <a-popover placement="top">
              <!--            <template slot="content">-->
              <!--              <div class="main">-->
              <!--                <div class="info">-->
              <!--                  <div class="element phone">+7(925)123-4567</div>-->
              <!--                  <a class="element email" href="mailto:opt@temp-auto.ru">-->
              <!--                    opt@temp-auto.ru-->
              <!--                  </a>-->
              <!--                  <div class="element time">с 9-00 до 18-00</div>-->
              <!--                </div>-->
              <!--              </div>-->
              <!--            </template>-->
              <template slot="title">
                <div class="main">
                  <div class="element name">{{ user.client.name }}</div>
                  <div class="element person">{{ user.name }}</div>
                </div>
              </template>
              <div class="user__info">
                <a-avatar class="user__avatar" :size="36" icon="user" />
                <h4 class="user__name">{{ userName }}</h4>
              </div>
            </a-popover>
          </div>
          <div class="diller-info" v-if="isDealer">
            <a-popover placement="top">
              <template slot="title">
                <div class="main">
                  <div class="element name">{{ user.dealer.name }}</div>
                  <div class="element person">{{ user.name }}</div>
                </div>
              </template>
              <div class="user__info">
                <a-avatar class="user__avatar" :size="36" icon="user" />
                <h4 class="user__name">{{ userName }}</h4>
              </div>
            </a-popover>
          </div>
          <div class="diller-info" v-if="isOwner || isAdmin">
            <div class="user__info">
              <a-avatar class="user__avatar" :size="36" icon="user" />
              <div>
                <h4 class="user__name">{{ userName }}</h4>
                <span v-show="isOwner" class="status-owner">
                  <strong>Статус:</strong>
                  Импортер
                </span>
              </div>
            </div>
          </div>
          <div class="user__logout">
            <a-tooltip placement="topLeft" title="Выход">
              <a-button
                @click="onLogout"
                type="link"
                shape="circle"
                icon="logout"
                size="large"
              />
            </a-tooltip>
          </div>
        </div>
      </div>
    </a-layout-sider>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"

// Menu
import ADMIN from "../store/__mock/menu/ADMIN"
import IMPORTER_ADMIN from "../store/__mock/menu/IMPORTER_ADMIN"
import IMPORTER_OPERATOR from "../store/__mock/menu/IMPORTER_OPERATOR"
import IMPORTER_ANALYST from "../store/__mock/menu/IMPORTER_ANALYST"
import IMPORTER_ORDER_OPERATOR from "../store/__mock/menu/IMPORTER_ORDER_OPERATOR"
import DEALER_ADMIN from "../store/__mock/menu/DEALER_ADMIN"
import DEALER_OPERATOR from "../store/__mock/menu/DEALER_OPERATOR"
import DEALER_ANALYST from "../store/__mock/menu/DEALER_ANALYST"
import DEALER_ORDER_OPERATOR from "../store/__mock/menu/DEALER_ORDER_OPERATOR"
import NSTO_ADMIN from "../store/__mock/menu/NSTO_ADMIN"
import NSTO_OPERATOR from "../store/__mock/menu/NSTO_OPERATOR"
import NSTO_ANALYST from "../store/__mock/menu/NSTO_ANALYST"
import NSTO_ORDER_OPERATOR from "../store/__mock/menu/NSTO_ORDER_OPERATOR"
import NSTO_OPERATOR_LOYALTY from "../store/__mock/menu/NSTO_OPERATOR_LOYALTY"

export default {
  name: "Slider",
  // watch: {
  //   $route() {
  //     console.log("default route path", this.$route.path)
  //     if (this.$route.path === "/") {
  //       this.defaultSelectedId = ["SearchDetails"]
  //     }
  //   },
  // },
  data() {
    return {
      shownMenu: true,
      iconType: "menu-fold",
      positionLeft: "0",
      positionLeftBurger: "255px",
      defaultSelectedId: ["SearchDetails"],
      collapsed: false,
      ordersCounts: 0,
      refundsCounts: 0,
      openKeys: [],
      menu: {
        roles: {
          ...ADMIN,
          ...IMPORTER_ADMIN,
          ...IMPORTER_OPERATOR,
          ...IMPORTER_ANALYST,
          ...IMPORTER_ORDER_OPERATOR,
          ...DEALER_ADMIN,
          ...DEALER_OPERATOR,
          ...DEALER_ANALYST,
          ...DEALER_ORDER_OPERATOR,
          ...NSTO_ADMIN,
          ...NSTO_OPERATOR,
          ...NSTO_ANALYST,
          ...NSTO_ORDER_OPERATOR,
          ...NSTO_OPERATOR_LOYALTY,
        },
      },
    }
  },
  mounted() {
    this.setCounterInterval()
    this.getMenuValues()
    this.checkBasket()
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      currentRoles: "auth/roles",
      rolesNames: "auth/rolesNames",
      isLoggedIn: "auth/isLoggedIn",
      basket: "basket/basket",
      basketProducts: "basket/products",
      basketCount: "basket/count",
      isClient: "auth/isBuyer",
      isBuyer: "auth/isBuyer",
      isDealer: "auth/isDealer",
      isOwner: "auth/isOwner",
      isAdmin: "auth/isAdmin",
      role: "auth/role",
    }),
    shownCounts() {
      return this.isDealer || this.isBuyer
    },
    currentMenu() {
      return this.menu.roles[this.currentRoles[0]]?.items
    },
    selectedKeys() {
      return this.$route.name.split("-")
    },
    userName() {
      return this.isDealer
        ? "Dealer"
        : this.isOwner
        ? "Owner"
        : this.isBuyer
        ? "Buyer"
        : this.isAdmin
        ? "Admin"
        : "Client"
    },
  },
  watch: {
    isClient() {
      this.checkBasket()
    },
  },
  methods: {
    ...mapActions({
      logout: "auth/logout",
      getBasket: "basket/getBasket",
      getRefundStatuses: "orders/getRefundStatuses",
      getOrderStatuses: "orders/getOrderStatuses",
    }),
    setCounterInterval() {
      const TIME_VALUE = 10000

      this.getCounts()

      setInterval(() => {
        this.getCounts()
      }, TIME_VALUE)
    },
    getMenuValues() {
      const shownMenu = JSON.parse(localStorage.getItem("shownMenu"))

      if (shownMenu === false) {
        this.shownMenu = false
        this.iconType = "menu-unfold"
        this.positionLeft = "-250px"
        this.positionLeftBurger = "5px"
      }
    },
    async getCounts() {
      const refunds = await this.getRefundStatuses()
      const orders = await this.getOrderStatuses()

      let refundsCount = 0
      let ordersCount = 0

      for (const key in refunds.data) {
        refundsCount += refunds.data[key].scount
      }

      for (const key in orders.data) {
        ordersCount += orders.data[key].scount
      }

      if (ordersCount !== 0 && this.isBuyer) {
        this.openKeys = ["Orders"]
      }

      this.refundsCounts = refundsCount
      this.ordersCounts = ordersCount
    },
    onLogout() {
      this.logout()
      this.$router.push("/login")
    },
    onMenuItemClick(path) {
      if (path !== this.$route.path) {
        this.$router.push(path)
      }
    },
    canWatch(requiredRole) {
      return this.currentRoles.includes(this.rolesNames[requiredRole])
    },
    checkBasket() {
      if (!this.basket && this.isClient) {
        this.getBasket()
      }
    },
    hideMenu() {
      if (this.shownMenu) {
        localStorage.setItem("shownMenu", "false")

        this.iconType = "menu-unfold"
        this.positionLeft = "-250px"
        this.positionLeftBurger = "5px"
      } else {
        localStorage.setItem("shownMenu", "true")

        this.iconType = "menu-fold"
        this.positionLeft = "0"
        this.positionLeftBurger = "255px"
      }

      this.$emit("hide-menu", this.shownMenu)

      this.shownMenu = !this.shownMenu
    },
  },
}
</script>

<style lang="scss" scoped>
.burger {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 101;
  bottom: 5px;
  left: 255px;
  width: 40px;
  height: 40px;
  transition: 0.3s;

  border-radius: 50%;
  background-color: #1ba57a;
}
</style>

<style lang="scss">
@import "../assets/sass/variables";
a.menu-link {
  color: rgba(255, 255, 255, 0.65);
}
/* TODO: убрать important */
.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: transparent !important;
}
.submenu {
  padding-left: 30px !important;
}
.search-menu {
  &__collapse-button {
    margin-top: 10px;
    margin-left: 4px;
    margin-bottom: 10px;

    background-color: #1980ff;
  }
}
.slider {
  position: relative;

  .user {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    color: white;
    padding: 10px 0;

    &__content {
      padding: 0 10px;
    }

    &__info {
      display: flex;
      align-items: center;
    }

    &__avatar {
      color: gray;
      background-color: white;
    }

    &__name {
      margin: 0 20px;
      color: white;
    }

    &__divider {
      margin: 10px 0;
      opacity: 0.2;

      &-logo {
        margin-top: 0;
      }
    }

    &__logout {
      position: absolute;
      right: 5px;
      top: 30px;
    }
  }

  &.ant-layout-sider {
    background: $color-brand-grey-dark;
  }

  .ant-menu-dark {
    background: $color-brand-grey-dark;
  }
}

.item {
  display: flex;
  align-items: center;
  position: relative;
}

.diller-info {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  color: white;

  line-height: normal;

  .main {
    display: flex;
    flex-direction: column;

    margin: 0 15px;

    .element {
      padding-top: 2px;
    }

    .title,
    .name {
      font-weight: 900;
    }

    .title {
      font-size: 15px;
    }

    .name {
      font-size: 18px;
    }
  }

  .avatar {
    width: 58px;
    height: 58px;

    margin: 0 5px;
  }

  .info {
    margin-left: 15px;

    font-weight: 500;

    .element {
      display: block;
      padding-top: 2px;
    }
  }
}

.status-owner {
  font-size: 12px;
  margin-left: 20px;
  strong {
    text-transform: uppercase;
    font-size: 10px;
  }
}

.slider-logo-wrap {
  width: 100%;
  height: 70px;
  padding: 10px;

  .slider-logo-image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
}
</style>
